
import React from "react"
import { Link } from "gatsby"
import LinkedInIcon from '../../static/linkedin.png'
import TwitterIcon from '../../static/twitter.png'
import GoogleScholarIcon from '../../content/assets/256px-Google_Scholar_logo.svg.png'
import styled from "styled-components"
import { rhythm, scale } from "../utils/typography"

const title = "Allegra H. Fullerton";

const HeaderStyle = styled.header`
   display: flex;
   max-width: 1200px;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;

   margin-bottom: ${rhythm(2)};
   
   .selected {
   }
          
`;

const HeaderLeftTitle = styled.h1`
    margin: 0;

    a {
      text-decoration: none;
    }
`;

const HeaderLeftTitleSmall = styled.h3`
    margin: 0;
`;

const HeaderRightItems = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderLabel = styled.span`
    display: inline-block;
    margin: 0 10px 0 10px;
    ${scale(3/4)};
`;

const HeaderIcon = styled.span`
    margin: 0 10px 0 10px;
    font-size: 0;
    line-height: 0;
`

const HeaderIconImg = styled.img`
  width: 40px;
  margin-bottom: 0;
      ${scale(0)}
`

const HeaderTitle = ({location}) => {
    //const rootPath = `${__PATH_PREFIX__}/`
    //const blogPath = `${__PATH_PREFIX__}/blog/`

    //if (location.pathname === rootPath || location.pathname === blogPath) {
      return (
        <HeaderLeftTitle >
          <Link
            //to={location.pathname === blogPath ? `/blog/` : `/`}
            to={'/'}
          >
            {title}
          </Link>
        </HeaderLeftTitle>
      )
    /*
    } else {
      return (
        <HeaderLeftTitleSmall>
          <Link to={`/blog/`} >
            {title}
          </Link>
        </HeaderLeftTitleSmall>
      )
    }
    */
}

function MaybeLink({location, to, children}) {
    if (location.pathname === to)
        return <div className="selected">{children}</div>
    else
        return <Link to={to}>{children}</Link>
}

export default function Header({location}) {

    return <HeaderStyle>
      <HeaderTitle location={location} />
      <HeaderRightItems>
      {
         /*<HeaderLabel>
          <MaybeLink location={location} to={`/blog/`} >
            Blog
          </MaybeLink>
        </HeaderLabel>
        */ 
      }
        {/*<HeaderLabel>
          <MaybeLink location={location} to={`/resume/`} >
            Resume
          </MaybeLink>
        </HeaderLabel>
        */}
        <HeaderLabel>
          <MaybeLink location={location} to={`/home/`} >
            Home
          </MaybeLink>
        </HeaderLabel>
        <HeaderLabel>
          <MaybeLink location={location} to={`/research/`} >
            Research
          </MaybeLink>
        </HeaderLabel>
        <HeaderIcon>
          <a target="_blank" href="https://www.linkedin.com/in/allegrafullerton/">
            <HeaderIconImg alt="linked in" src={LinkedInIcon} />
          </a>
        </HeaderIcon>
        <HeaderIcon>
          <a target="_blank" href="https://scholar.google.com/citations?view_op=list_works&hl=en&hl=en&user=JCxgp08AAAAJ&sortby=pubdate">
            <HeaderIconImg alt="Google Scholar" src={GoogleScholarIcon} />
          </a>
        </HeaderIcon>
        <HeaderIcon>
          <a target="_blank" href="https://twitter.com/allegra_fuller">
            <HeaderIconImg alt="twitter" src={TwitterIcon} />
          </a>
        </HeaderIcon>
      </HeaderRightItems>
    </HeaderStyle>
}
