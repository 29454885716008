import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import GlobalStyle, { bg, bgalt } from '../components/globalStyle'
import Header from './header'

import { rhythm, scale } from "../utils/typography"

const title = "Allegra Fullerton";

const UpperContent = styled.div`
    background-color: ${bg};
`
const UpperContentCenter = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: ${rhythm(48)};
    padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`

const PrimaryContent = styled.main`
  max-width: 1200px;
`

class Layout extends React.Component {
  render() {
    const { location, children } = this.props

    return (
      <Wrapper>
        <GlobalStyle />
        <UpperContent>
          <UpperContentCenter>
            <Header location={location} />
            <PrimaryContent>
              {children}
            </PrimaryContent>
          </UpperContentCenter>
        </UpperContent>
        <Footer>
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  width: 100%;
`;

export default Layout
