import Typography from "typography"
import StAnnes from "typography-theme-st-annes"

const typography = new Typography(StAnnes);

const origOverrideStyles = StAnnes.overrideStyles;
StAnnes.overrideStyles = (fns, options) => {
    const data = origOverrideStyles(fns, options);
    delete data.a.color;
    delete data['a:hover,a:active'];
    return data;
}

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
