
import styled, { createGlobalStyle } from 'styled-components'
import { scale, rhythm } from '../utils/typography'

const raisin_black = '#252425'

const tareq_grey_bg = '#e3e3e3'
const tareq_grey_bgalt = '#f6f6f8'
const tareq_highlight = '#c60021'

export const bg = tareq_grey_bg;
export const bgalt = tareq_grey_bgalt;
export const fg = 'black'
export const fg_tint = '#111'
export const fg_highlight = '#670881'

const style = createGlobalStyle`
body {
    background: ${bgalt};
    color: ${fg};
    margin-left: auto;
    margin-right: auto;
}

h1 { 
  color: ${fg};
}

h2,h3,a {
  color: ${fg_highlight};
}

a:hover {
  color: ${fg_tint};
}

h1 {
  ${scale(2)};
}

h3 {
  ${scale(1)};
}

p {
  ${scale(.5)};
}

a {
  text-decoration: underline;
  text-decoration-color: black;
}

br {
  height: 0.8em;
  margin: 20px;
  font-size: .8em;
}
`

/*

@keyframes thud {
  0% {
    transform: translateY(-10px)
    color: hsl(0,0,0);
  }
  100% {
    transform: translateY(0px)
    color: black;
  } 
}

h1,h2,h3 {
   animation: thud .5s both;
}
*/

export default style;
